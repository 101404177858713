import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useLocation, useHistory } from "react-router-dom";

import DownloadCsv, { CsvLinkProps } from "../../../components/DownloadCsv";
import { LoadingUI } from "../../../components/Loading";
import {
  BillingCustomerFilterInput,
  BillingCustomersCsvRowFragment,
  CustomerBillingCsvDownloadQuery,
  CustomerBillingSearchResultsFragment,
} from "../../../generated/graphql";
import {
  useCustomerBillingSearchResultsQuery,
  useCustomerBillingCsvDownloadLazyQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import {
  searchSettings,
  searchSettingsForAllData as csvSetting,
} from "../../../lib/constants";
import CustomerBillingSearchResultsPage from "./CustomerBillingSearchResultsPage";

dayjs.extend(utc);
dayjs.extend(tz);

export type CsvData = {
  name: string;
  kana: string;
  memberId: string;
  mailMagazineAgreement: string;
  tel: string;
  planName: string;
  submittedAt: string;
  canceledAt: string;
};

export const headers = [
  { label: "名前", key: "name" },
  { label: "フリガナ", key: "kana" },
  { label: "会員ID(メールアドレス)", key: "memberId" },
  { label: "メルマガ配信許諾", key: "mailMagazineAgreement" },
  { label: "電話番号", key: "tel" },
  { label: "プラン名", key: "planName" },
  { label: "申込年月日", key: "submittedAt" },
  { label: "解約年月日", key: "canceledAt" },
];

const CustomerBillingSearchResultsPageContainer: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{
    filter: BillingCustomerFilterInput;
  }>();
  const filter = location.state?.filter;
  const [limit, setLimit] = useState(searchSettings.limit);
  const [billingCustomers, setBillingCustomers] =
    useState<CustomerBillingSearchResultsFragment>({
      totalCount: 0,
      nodes: [],
    });
  const [csvData, setCsvData] = useState<CsvData[]>();

  const errorRouter = useErrorRouter();

  const { data, loading, refetch } = useCustomerBillingSearchResultsQuery({
    onError: errorRouter,
    variables: {
      filter,
      limit,
    },
  });

  const [fetchCSVData, { loading: loadingCSVQuery }] =
    useCustomerBillingCsvDownloadLazyQuery({
      onCompleted: (data) => {
        const replacedData = replaceToCsvData(data);
        setCsvData(replacedData);
      },
      onError: errorRouter,
      variables: {
        filter: filter,
        offset: csvSetting.offset,
        limit: csvSetting.limit,
      },
    });

  const csvLinkProps: CsvLinkProps | undefined = useMemo(() => {
    // validate
    if (loadingCSVQuery || !csvData) {
      return undefined;
    }

    return {
      filename: `月額会員一覧_${dayjs().format("YYYYMMDD")}.csv`,
      headers: headers,
      data: csvData,
    };
  }, [csvData, loadingCSVQuery]);

  useEffect(() => {
    if (data?.billingCustomers) {
      setBillingCustomers(data?.billingCustomers);
    }
  }, [data?.billingCustomers]);

  /**
   * graphql → CSV用のオブジェクトへ変換
   */
  const replaceToCsvData = useCallback(
    (queryData: CustomerBillingCsvDownloadQuery): CsvData[] => {
      return queryData.billingCustomers.nodes.map(
        (billingCustomer: BillingCustomersCsvRowFragment): CsvData => {
          const {
            profile,
            submittedAt,
            canceledAt,
            plan,
            mailMagazineAgreement,
          } = billingCustomer;
          return {
            name: `${profile?.familyName} ${profile?.givenName}`,
            kana: `${profile?.familyNameKana} ${profile?.givenNameKana}`,
            memberId: `${profile?.memberId || ""}`,
            mailMagazineAgreement: mailMagazineAgreement ? "true" : "false",
            tel: `${profile?.tel || ""}`,
            planName: plan.name,
            submittedAt: dayjs(submittedAt)
              .tz("Asia/Tokyo")
              .format("YYYY/MM/DD"),
            canceledAt: canceledAt
              ? dayjs(canceledAt).tz("Asia/Tokyo").format("YYYY/MM/DD")
              : "",
          };
        }
      );
    },
    []
  );

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + searchSettings.offset);
    await refetch({
      filter,
      limit: limit + searchSettings.offset,
    });
  }, [filter, refetch, limit]);

  const toSearchPage: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      history.push("/customer/billing/search", {
        filter,
      });
    },
    [history, filter]
  );

  return loading && billingCustomers.totalCount === 0 ? (
    <LoadingUI title="検索中" />
  ) : (
    <>
      <CustomerBillingSearchResultsPage
        billingCustomers={billingCustomers}
        hasAdminAuthority={data?.hasAdminAuthority || false}
        onDisplayMore={onDisplayMore}
        toSearchPage={toSearchPage}
        onClickDownload={fetchCSVData}
      />
      <DownloadCsv csvLinkProps={csvLinkProps} />
    </>
  );
};
export default CustomerBillingSearchResultsPageContainer;
