import React from "react";

import { CsvDownloadButtonUI } from "../../../components/CsvDownloadButton";
import { LiberalArtsVideoLessonRatingPageQuery } from "../../../generated/graphql";
import { VideoLessonRatingTableUI } from "./components/VideoLessonRatingTable";

export type LiberalArtsVideoLessonRatingPageProps = {
  data: LiberalArtsVideoLessonRatingPageQuery;
  onClickDownload: () => void;
};

const LiberalArtsVideoLessonRatingPage: React.VFC<
  LiberalArtsVideoLessonRatingPageProps
> = ({ data, onClickDownload }) => {
  return (
    <div>
      <div className="flex justify-between mb-4">
        <p className="mt-auto" data-testid="summaries-total-count">
          {data.videoLessonRatingSummaries.totalCount}件表示
        </p>
        <CsvDownloadButtonUI
          buttonTitle="5千件ダウンロード"
          onClick={onClickDownload}
        />
      </div>
      <VideoLessonRatingTableUI ratingData={data.videoLessonRatingSummaries} />
    </div>
  );
};

export default LiberalArtsVideoLessonRatingPage;
